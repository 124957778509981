import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import FaqLayout from "../components/layout/faq"

const FaqDocument  = () => {
    return (
        <FaqLayout>
            <h2 className="is-size-4"><FontAwesomeIcon icon={faQuestionCircle} className="mr-3 has-text-info"/>よくあるご質問</h2>
            <p>サービスに関する技術的な内容や書籍に関するものなど、皆様から寄せられたよくある質問とその回答を掲載しています。</p>
            <article className="mt-4">
                <p className="has-text-weight-bold">お問い合わせの多いご質問</p>
                <ul>
                    <li><Link to="/faq/app#web-%E7%89%88%E3%81%8C%E3%81%86%E3%81%BE%E3%81%8F%E5%8B%95%E3%81%8B%E3%81%AA%E3%81%84">Web 版がうまく動かない</Link></li>
                    <li><Link to="/faq/account#%E3%83%AD%E3%82%B0%E3%82%A4%E3%83%B3%E3%81%A7%E3%81%8D%E3%81%AA%E3%81%84">ログインできない</Link></li>
                    <li><Link to="/faq/ebook#%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84%E3%82%92%E8%AA%A4%E3%81%A3%E3%81%A6%E8%B3%BC%E5%85%A5%E3%81%97%E3%81%9F">コンテンツを誤って購入した</Link></li>
                </ul>
            </article>
        </FaqLayout>
    )
}

export default FaqDocument;
import React from "react"
import { Link } from "gatsby"

const FaqMenu = () => {
  return (
    <aside class="menu mx-4">
      <p class="menu-label">
        FAQ
      </p>
      <ul className="menu-list">
        <li>
          <Link to="/faq">ホーム</Link>
        </li>
        </ul>
      <p class="menu-label">
        よくある質問
      </p>
      <ul className="menu-list">
        <li>
          <Link to="/faq/app">アプリ</Link>
        </li>
        <li>
          <Link to="/faq/ebook">書籍</Link>
        </li>
        <li>
          <Link to="/faq/store">ストアサイト</Link>
        </li>
        <li>
          <Link to="/faq/account">アカウント</Link>
        </li>
      </ul>
     </aside>
    );
}

export default FaqMenu;